import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { IPlayer, IReqRes } from '../../../shared/interfaces';
import { ActionEnum, ReqResTypeEnum } from '../../../shared/enums';
import { SocketService, ValuesMapService } from '../../../core/services';
import { processMapEntity } from '../../../shared/helpers';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { filter, map } from 'rxjs/operators';
import { IMPORT_PENDING } from '@shared/constants';

const PLAYER_ID_KEY = 'playerId';

const DEF_ID = 4440000000017;

@Injectable({
  providedIn: 'root',
})
export class PlayersService {
  scannedId$ = new Subject<number>();
  gendersMap = this.valuesMapService.gendersMap;
  ageGroupMap = this.valuesMapService.ageGroupMap;
  handsMap = this.valuesMapService.handsMap;

  private playersMap = new Map();
  private players$ = new BehaviorSubject<IPlayer[]>([]);

  constructor(
    readonly socketService: SocketService,
    readonly valuesMapService: ValuesMapService,
    readonly http: HttpClient
  ) {
    localStorage.setItem(PLAYER_ID_KEY, `${DEF_ID}`);
    this.loadPlayers().subscribe();
  }

  processing(res: IReqRes<any>) {
    if (res.data && !sessionStorage.getItem(IMPORT_PENDING)) {
      this.setNextPlayerId(res.data.id);
    }
    const resData = res.data;
    res.data = this.mapPlayer(resData);
    processMapEntity(this.playersMap, res);
    this.players$.next(Array.from(this.playersMap.values()));
  }

  getPlayers() {
    return this.players$.asObservable();
  }

  getPlayersByPlace(ids: number[]): IPlayer[] {
    return ids?.reverse()?.map((id, index) => ({...this.getPlayer(id), place: index + 1})).filter(p => !p?.name?.includes('bot'));
  }

  getPlayer(id: number): IPlayer {
    if (id) {
      return this.playersMap.get(id);
    }
    return null;
  }

  savePlayer(data: IPlayer, action: ActionEnum) {
    this.socketService.sendSocketMessage<IPlayer>(
      ReqResTypeEnum.PERSON,
      data,
      action
    );
  }

  getNewPlayerId() {
    return +localStorage.getItem(PLAYER_ID_KEY);
  }

  setNextPlayerId(id: number) {
    if (`${id}`?.startsWith('444') && id >= +localStorage.getItem(PLAYER_ID_KEY)) {
      let nextId = '';
      let nextNumber = +id + 1;

      while (!nextId) {
        const checkSum = `${nextNumber}`.split('').reduce((p, v, i) => {
          return i % 2 === 0 ? p + +v : p + 3 * +v;
        }, 0);
        if (checkSum % 10 === 0) {
          nextId = `${nextNumber}`;
        }
        nextNumber += 1;
      }

      localStorage.setItem(PLAYER_ID_KEY, nextId);
    }
  }

  mapPlayer(data) {
    return {
      ...data,
      id: +data.id,
      ageValue: this.ageGroupMap[data.age],
      genderValue: this.gendersMap[data.gender],
      handValue: this.handsMap[data.hand],
      team: data?.team || 'NONE',
    };
  }

  loadPlayers() {
    return this.http.get<any>(`${environment.apiUrl}persons`).pipe(
      map(res => {
        res.active.forEach(p => {
          this.playersMap.set(+p.id, this.mapPlayer(p));
          this.setNextPlayerId(+p.id);
        });
        this.players$.next(Array.from(this.playersMap.values()));

        return null;
      })
    );
  }

  setScannedId(id: number) {
    this.scannedId$.next(id);
  }

  getScannedId() {
    return this.scannedId$.asObservable().pipe(
      filter(res => !!res)
    );
  }

  getPlayersCount() {
    return this.playersMap.size;
  }
}
